@import url('https://fonts.googleapis.com/css2?family=Bungee+Outline&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .hamburger div:first-child {
        @apply rotate-45 md:translate-y-2 translate-y-2.5 bg-white z-[1];
    }

    .hamburger div:nth-child(2) {
        @apply -rotate-45 md:-translate-y-1.5 translate-y-0.5 bg-white z-[1];

    }
    .hamburger div:last-child{
        @apply -rotate-45 md:-translate-y-5 -translate-y-1.5 bg-white z-[1];
    }
    
}
