body {
  overflow-x: hidden;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 0.1s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.typewriter-anim::after,
.typewriter-anim::before {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.typewriter-anim::before {
  background: url("../public/images/homepage.png") no-repeat center center fixed;
  background-size: cover;
  animation: typeWriter 1.5s ease 0.5s forwards;
}

.typewriter-anim::after {
  width: 10px;
  background: rgb(0, 60, 60);
  animation: typeWriterBlock 1.5s ease 0.5s forwards;
}

@keyframes typeWriter {
  to {
    left: 100%;
  }
}

@keyframes typeWriterBlock {
  99% {
    opacity: 0;
  }
  100% {
    width: 0;
    left: 100%;
    opacity: 0;
  }
}

@keyframes breathing {
  0% {
    opacity: 1;
  }
  50% {
    /* opacity: 0.7; */
    scale: 1.03;
    /* line-height: 2.5rem; */
  }
  100% {
    opacity: 1;
  }
}

.typewriter-anim2::after,
.typewriter-anim2::before {
  content: " ";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.typewriter-anim2::before {
  background: url("../public/images/homepage.png") no-repeat center center fixed;
  background-size: cover;
  animation: typeWrite 3s steps(15) 1s infinite;
}

.typewriter-anim2::after {
  width: 7px;
  background: rgb(0, 60, 60);
  animation: typeWrite 3s steps(15) 1s infinite, blink 500ms steps(15) infinite;
}

.typeAnim {
  transition: 1s ease-in-out;
}

.typeAnim.show {
  left: 100%;
}
@keyframes typeWrite {
  40%,
  70% {
    left: 100%;
  }
  100% {
    left: 0%;
  }
}

@keyframes blink {
  to {
    opacity: 0;
  }
}


.list.animate:first-child {
  animation: showUp .5s ease forwards 0.3s;
}

.list.animate:first-child + * {
  animation: showUp .5s ease forwards 0.6s;
}

.list.animate:first-child + * + * {
  animation: showUp .5s ease forwards 0.9s;
}

.list.animate:first-child + * + * + * {
  animation: showUp .5s ease forwards 1.2s;
}

.list.animate:first-child + * + * + * + * {
  animation: showUp .5s ease forwards 1.5s;
}

.list.animate:first-child + * + * + * + * + * {
  animation: showUp .5s ease forwards 1.8s;
}

.list.animate:first-child + * + * + * + * + * + * {
  animation: showUp .5s ease forwards 2.1s;
}

.list.animate:first-child + * + * + * + * + * + * + * {
  animation: showUp .5s ease forwards 2.4s;
}

@keyframes showUp {
  from {
    transform: scale(0) rotate(30deg);
  }
  to {
    transform: scale(1) rotate(0deg);
  }
}

.autoshowLeft-anim {
  animation: fromLeft .5s ease-in-out;
}

@keyframes fromLeft {
  from{
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}